<!--=========================================================================================
  File Name: ResultCount.vue
  Description: ResultCount Component
==========================================================================================-->
<template>
    <div class="content">
        <table class="result-count-container" style="margin-top:0.7vh">
            <tr>
                <td>
                    <div class = 'e shoe-item shoe-text'>
                        <span class="res-span"  v-if = "gameType != 'DT'">P</span>
                        <span class="res-span"  v-if = "gameType == 'DT'">Tr</span>
                    </div>
                </td>
                <td class="result-val player-color" style="padding-left:0px;padding-right:1vw">
                    {{ playerShoe }}
                </td>
                <td>
                    <div class = 'i shoe-item shoe-text'>
                        <span class="res-span">T</span>
                    </div>
                </td>
                <td class="result-val tie-color" style="padding-left:0px;padding-right:1vw">
                    {{ tieShoe }}
                </td>
                <td class="p-top">
                    <div class = 'a shoe-item shoe-text'>
                        <span class="res-span"  v-if = "gameType != 'DT'">B</span>
                        <span class="res-span"  v-if = "gameType == 'DT'">Dr</span>
                    </div>
                </td>
                <td class="p-top result-val banker-color" style="padding-left:0px;padding-right:1vw">
                    {{ bankerShoe }}
                </td>
                <td class="p-top">
                    <div class = 'i-b shoe-item shoe-text'>
                        <span class="res-span">T</span>
                    </div>
                </td>
                <td class="p-top result-val tie-color" style="padding-left:0px">
                    {{totalShoe }}
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: 'ResultCount',
    computed: {
      totalShoe() {
          return this.shoe.length || 0
      },
      bankerShoe() {
          return this.shoe.split('').filter(s => s == 'a' || s == 'b' || s == 'c' || s == 'd').length || 0
      },
      playerShoe() {
          return this.shoe.split('').filter(s => s == 'e' || s == 'f' || s == 'g' || s == 'h').length || 0
      },
      tieShoe() {
          return this.shoe.split('').filter(s => s == 'i' || s == 'j' || s == 'k' || s == 'l').length || 0
      },
    },
    data() {
        return {}
    },
    props: {
        shoe: {
            required: true, type: String
        },
        gameType: {required: true, type: String},
    }
}
</script>

<style scoped>
    .rm-padding {
        padding-left: 0px;
        padding-right: 0px;
    }
    .content {
        width: 100%;
    }

    .shoe-text {
        font-size: 1.2vh;
        padding-top: 0.7vh;
    }

    .shoe-item {
        height: 2.5vh;
        width: 2.5vh;
    }

    .result-val {
        padding-left: 0vh;
        font-size: 1.2vh;
        font-weight: 600;
        color: #fff;
    }

    .a {
        background:
        url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet'><linearGradient x1='0' y1='0' x2='100%' y2='100%' id='gradient'><stop stop-color='%23FF0000'  offset='0'/><stop stop-color='%23f0000' offset='100%'/></linearGradient><circle fill-opacity='0.80' fill='url(%23gradient)' cx='250' cy='250' r='240'/></svg>") no-repeat;
    }

    .e {
        background:
        url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet'><linearGradient x1='0' y1='0' x2='100%' y2='100%' id='gradient'><stop stop-color='%230161ee'  offset='0'/><stop stop-color='%23062237' offset='100%'/></linearGradient><circle fill-opacity='0.90' fill='url(%23gradient)' cx='250' cy='250' r='240'/></svg>") no-repeat;
    }

    .i {
        background:
        url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet'><linearGradient x1='0' y1='0' x2='100%' y2='100%' id='gradient'><stop stop-color='%2301ce00'  offset='0'/><stop stop-color='%2301ce00' offset='100%'/></linearGradient><circle fill-opacity='0.80' fill='url(%23gradient)' cx='250' cy='250' r='240'/></svg>") no-repeat;
    }

    .i-b {
        background:
        url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet'><linearGradient x1='0' y1='0' x2='100%' y2='100%' id='gradient'><stop stop-color='%23D2B48C'  offset='0'/><stop stop-color='%23D2B48C' offset='100%'/></linearGradient><circle fill-opacity='0.80' fill='url(%23gradient)' cx='250' cy='250' r='240'/></svg>") no-repeat;
    }

    .tie-color-beige{
        background-color: beige;
    }

    .result-count-container tr td{
        text-align: center;
    }

    .p-top {
        /* padding-top: 5px; */
    }
</style>

<!--=========================================================================================
  File Name: ResultRoad.vue
  Description: Result Road Component
==========================================================================================-->
<template>
<div class="content grid-lines" style="padding-top: 0.16vh">
    <div class="carfbig-road" ref="shoe-tbl">
        <!-- <img @click="scrollTarget('left')" v-if="showLeftArrow" src="/assets/images/gamelobby/btn_hisprev.png" alt="Prev Button" class="big-road-prev"> -->
        <div class="rs-ctnr d-flex flex-row grid-y grid-y-repeat-big" v-bind:style="{width: shoeW }">
             <div class="flex-column" v-for="(shoeM,x) in bigRoadMatrix" :key="x">
                 <div v-for="(shoe, i) in shoeM" v-bind:key="shoe.index + '-' + i" style="height: 1.61vh;width: 1.61vh">
                     <div class="big-road-shoe" style="height:1.61vh;width: 1.61vh;line-height: 1.0 !important;"
                           v-bind:class="[{ animate_latest: x == bigRoadMatrix.length - 1 && (shoe.shoe !='' && i == $getLasttIndex(bigRoadMatrix, x) )}, shoe.shoe + '-big-road']">
                           <span style ="font-size: 1.2vh;" v-if="shoe.count > 0">{{ shoe.count }}</span>
                     </div>
                 </div>
            </div>
        </div>
        <!-- <img @click="scrollTarget('right')" v-if="showRightArrow" src="/assets/images/gamelobby/btn_hisnext.png" alt="Next Button" class="big-road-next"> -->
    </div>
</div>
</template>
<script>
export default {
    name: 'ResultBigRoad',
    components: {},
    data() {
        return {
            showRightArrow: false,
            showLeftArrow: false,
            width: 0,
            window: {
                width: 0,
                height: 0
            },
            scrollW: 0,
            shoeW: '3000px'
        }
    },
    props: {
        shoeResults: {
            required: true,
            type: String
        },
    },
    computed: {
        bigRoadMatrix() {
            let matrix = [],
                prevShoe = '',
                columnIdx = -1,
                rowIdx = 1,
                sameShoeCnt = 0,
                tempColumnIdx = -1,
                tmpInd = 5,
                tieCnt = 0,
                idx = 0;
            const tieShoe = ['i', 'j', 'k', 'l']
            this.shoeResults.split('').forEach((shoe) => {
                if (!(tieShoe.indexOf(shoe) !== -1)) { // If shoe is not tie
                    if (!((this.$prevShoeArr(prevShoe)).indexOf(shoe) !== -1)) { // create new column
                        if (matrix[columnIdx + 1]) { // check if there's an over shoe, overwrite 1st column
                            matrix[columnIdx + 1][0] = {
                                shoe,
                                count: 0
                            }
                        } else {
                            matrix.push([{
                                shoe,
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }])
                        }
                        rowIdx = 1; // reset row index to 1 (next vertical shoe)
                        sameShoeCnt = 0 // reset same shoe count
                        columnIdx++; // increment column index
                        tempColumnIdx = columnIdx;
                        tieCnt = 0;
                    } else { // normal add
                        if (sameShoeCnt > rowIdx) {
                            matrix.push([{
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }])
                            tempColumnIdx++
                            matrix[tempColumnIdx][rowIdx] = {
                                shoe,
                                count: 0
                            }
                            if (tmpInd < 5) { //
                                if (matrix[tempColumnIdx][rowIdx + 1].shoe == '') {
                                    tempColumnIdx--
                                    rowIdx++
                                    tmpInd = rowIdx
                                }
                            }
                        } else {
                            if (matrix[tempColumnIdx] && matrix[tempColumnIdx][rowIdx].shoe == '') {
                                matrix[tempColumnIdx][rowIdx] = {
                                    shoe,
                                    count: 0
                                }
                            } else {
                                tmpInd = rowIdx - 1
                                // check if the matrix has enough length to handle dragon tail
                                if (matrix.length < tempColumnIdx) {
                                    matrix.push([{
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }])
                                }

                                matrix.push([{
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }])
                                tempColumnIdx++
                                rowIdx--
                                matrix[tempColumnIdx][rowIdx] = {
                                    shoe,
                                    count: 0
                                }

                                // Always check the bottom index
                                if (tmpInd < 5) {
                                    if (matrix[tempColumnIdx][rowIdx + 1].shoe == '') {
                                        tempColumnIdx--
                                        rowIdx++
                                        tmpInd = rowIdx
                                    }
                                }
                            }
                        }
                        if (rowIdx < tmpInd)
                            rowIdx++; // increment row index
                    }
                    if (columnIdx >= 0 && rowIdx > 0) {
                        idx++;
                        matrix[columnIdx][rowIdx - 1].index = idx;
                    }
                    prevShoe = shoe // set current shoe as prev
                    sameShoeCnt++; // same Shoe
                    tieCnt = 0 // reset tie counting.
                } else {
                    tieCnt++;
                    if (tempColumnIdx > 0) { // if Tie is the first result
                        if (sameShoeCnt > rowIdx) {
                            if (rowIdx > -1 && tempColumnIdx > -1) {
                                matrix[tempColumnIdx][rowIdx] = {
                                    shoe: prevShoe,
                                    count: tieCnt
                                }
                            }
                        } else {
                            matrix[tempColumnIdx][rowIdx - 1] = {
                                shoe: prevShoe,
                                count: tieCnt
                            }
                        }
                    }
                }
            })
            return matrix
        },
    },
    watch: {
      bigRoadMatrix: {
          handler(){
            this.handleResize();
          }
      }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        if (this.bigRoadMatrix.length > 16) {
            this.showRightArrow = true
            this.showLeftArrow = true
            this.handleResize()
        }
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            this.scrollW = (this.window.height * 1.61) / 100;
            if(this.$refs['shoe-tbl'])
              this.$refs['shoe-tbl'].scrollLeft = (this.bigRoadMatrix.length - 16) * this.scrollW
        },
        scrollTarget(move) {
            if (move == 'right') this.$refs['shoe-tbl'].scrollLeft += this.scrollW
            else this.$refs['shoe-tbl'].scrollLeft -= this.scrollW
        },
    },
}
</script>

<style>
.big-road-tbl td {
    border: 1px solid #D8D8D8;
    border-top: none !important;
}

.big-road-tbl {
    display: block;
    overflow: hidden;
}

.big-road-next {
    z-index: 11;
    position: absolute;
    height: 4.4vw;
    top: 55px;
    right: 15px;

}

.big-road-prev {
    z-index: 11;
    position: absolute;
    height: 8.5vh;
    top: 55px;
    left: 0;
}

.carfbig-road {
    overflow: hidden;
}
</style>

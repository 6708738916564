<!--=========================================================================================
  File Name: ResultRoad.vue
  Description: Result Road Component
==========================================================================================-->
<template>
<div class="content">
    <div class="bead-road  grid-y-repeat" ref="shoe-tbl">
        <img v-if="showLeftArrow" @click="scrollTarget('left')" src="/assets/images/gamelobby/btn_hisprev.png" alt="Prev Button" class="shoe-prev">
        <div class="rs-ctnr d-flex flex-row grid-y-repeat" v-bind:style="{width: shoeW }">
            <div class="flex-column" v-for="(shoeM,x) in shoeMatrix" :key="x">
                <div v-for="(shoe, i) in shoeM.split('')" :key="i"  style="height: 3.23vh;width: 3.23vh">
                    <div  class="shoe-item shoe-text"  style="height: 3.23vh;width: 3.23vh"
                          v-bind:class="[{ animate_latest: x == shoeMatrix.length - 1 && shoeM.split('').length -1 == i}, shoe]">
                          <span class='res-span' v-if = "typeGame !='DT'">{{ shoe | shoeText }}</span>
                          <span class='res-span' v-if = "typeGame =='DT'">{{ shoe | shoeTextDT }}</span>
                    </div>
                </div>
            </div>
        </div>
        <img v-if="showRightArrow" @click="scrollTarget('right')" src="/assets/images/gamelobby/btn_hisnext.png" alt="Next Button" class="shoe-next">
    </div>
</div>
</template>

<script>
export default {
    name: 'ResultRoad',
    components: {},
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    data() {
        return {
            showRightArrow: false,
            showLeftArrow: false,
            width: 0,
            window: {
                width: 0,
                height: 0
            },
            scrollW: 0,
            shoeW: '3000px',
        }
    },
    props: {
        shoeResults: {
            required: true,
            type: String
        },
        typeGame: {
            required: true,
            type: String
        },
    },
    computed: {
        shoeMatrix() { // divide shoe by 6
            return this.shoeResults.match(/.{1,6}/g) || []
        },

    },
    watch: {
      shoeMatrix: {
          handler(){
            this.handleResize();
          }
      }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        if (this.shoeMatrix.length > 4) {
            this.showRightArrow = true
            this.showLeftArrow = true
            this.handleResize();
        }
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            this.scrollW =(this.window.height * 3.23) / 100;
            if(this.$refs['shoe-tbl'])
                this.$refs['shoe-tbl'].scrollLeft = (this.shoeMatrix.length - 4) * this.scrollW
        },
        scrollTarget(move) {
            if (move == 'right') this.$refs['shoe-tbl'].scrollLeft += this.scrollW
            else this.$refs['shoe-tbl'].scrollLeft -= this.scrollW
        },
    },
}
</script>

<style>
.shoe-item {
    height: 100%;
    width: 100%;
}

.shoe-tbl td {
    border: 1px solid #D8D8D8;
    border-top: none !important;
}

.test {
    float: left;
}

.rs-ctnr {
    width: 3000px;
}

.bead-road {
    overflow: hidden;

}

.seperator {
    height: 100vh;
    width: 1.75vw;
    border-right: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    float: left;
}

.seperator-v {
    height: 1.75vw;
    width: 100%;
    border-bottom: 1px solid #E0E0E0;
}
</style>

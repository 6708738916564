<!--=========================================================================================
  File Name: GameRoom.vue
  Description: Game Room Component
==========================================================================================-->
<template>
<div :class="{ 'locked': game.isLocked}" class="room-container" @mouseover="mouseIn" @mouseleave="mouseLeave">
    <b-overlay :show="show && !game.isLocked && game.shoe != '' " rounded="sm" class="custom-overlay" variant="dark">
        <b-button class="play-game" @click="redirectGame(game.game, game.roomno, isMobile())" v-show="show && !game.isLocked && game.shoe != '' ">{{ $t('lobby.baccarat') }}</b-button>
        <div v-if="game.isLocked" class="locked-text">
            <h6>{{ $t('gameroom.closeTextHeader') }}</h6>
            <div>{{ $t('gameroom.closeTextTop') }}</div>
            <div>{{ $t('gameroom.closeTextBot') }}</div>
        </div>
        <div v-if="!game.isLocked" class="room-info">
            <div class="room-details">
                <div class="shoe-info" style="display: block;overflow: hidden">
                    <!-- <b-row style="height:100%;" v-if="game.shoe == '' ">

                    </b-row> -->
                    <b-row style="margin-left:0px;">
                        <!-- <b-col cols="5" class="grid-x-repeat">
                             <result-road :shoeResults="game.shoe" :typeGame="game.type"/>
                        </b-col> -->
                        <b-col style="padding-left: 0px !important;" v-if="game.shoe == '' " >
                            <div class="image-shuffle" >
                            <img class="img card-shuffle" src="/assets/images/closed-bg.png" />
                            <div class="bottom-text"><span>{{$t('lobby.cardShuffle') }}</span></div>
                            </div>
                        </b-col>
                        <b-col cols="12" style="padding-left: 14px;" v-if="game.shoe != '' ">
                            <b-row style='border-left: 1px solid rgba(0, 0, 0, 0.24);'>
                                <b-col cols="12" style="padding-left:0px !important;" class="grid-x grid-x-repeat-big">
                                    <result-big-road :shoeResults="game.shoe" />
                                </b-col>
                            </b-row>
                            <b-row style='padding-top: 1px;border-top: 1px solid rgba(0, 0, 0, 0.24);border-left: 1px solid rgba(0, 0, 0, 0.24);'>
                                <b-col cols="12" style="padding-left:0px !important;" class="grid-x grid-x-repeat-big-eye">
                                    <result-big-eye-road :shoeResults="game.shoe" :commonMatrix="commonMatrix[0]" />
                                </b-col>
                            </b-row>
                            <b-row style='padding-top: 2px;border-top: 1px solid rgba(0, 0, 0, 0.24);border-left: 1px solid rgba(0, 0, 0, 0.24);'>
                                <b-col cols="6" style="padding-right: 0px;padding-left:0px !important;" class="grid-x grid-x-repeat-small">
                                    <result-small-road :shoeResults="game.shoe" :commonMatrix="commonMatrix[1]" />
                                </b-col>
                                <b-col cols="6" style="padding-left: 0px;padding-right:0px" class="grid-x grid-x-repeat-roach">
                                    <result-cockroach-road :shoeResults="game.shoe" :commonMatrix="commonMatrix[2]" />
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
                <div class="details">
                    <div class="room-time">{{ roomCount + 1 }}</div>
                    <div class="room-round" v-bind:style="{width: roomRoundW + 'vw'}" v-if = "game.game == 'EVO' ">{{ getEvoRoomno(game.roomno) + " : " + getRoundNumber(game.roomorder) }}</div>
                    <div class="room-round" v-bind:style="{width: roomRoundW + 'vw'}"  v-else>{{ game.roomno + ' : ' + getRoundNumber(game.roomorder) }}</div>
                    <div class="banker-player" style="margin-top:1vh">
                        <result-count :shoe="game.shoe" :gameType="game.type" />
                    </div>
                    <div class="room-time room-time-new"><timer :timeLeft="gameTimer" /></div>
                </div>
            </div>
            <div class="logo" :style="{ backgroundImage: `url(${$t('publicImgUrl') + $t('gameLogoURL.' + gameCode)})` }"></div>
        </div>
        <div class="overlay">
        </div>
    </b-overlay>
</div>
</template>

<script>
import ResultCount from '@/components/baccarat/roads/ResultCount.vue';
// import ResultRoad from '@/components/baccarat/roads/ResultRoad'
import ResultBigRoad from '@/components/baccarat/roads/ResultBigRoadLobby'
import ResultBigEyeRoad from '@/components/baccarat/roads/ResultBigEyeRoad'
import ResultSmallRoad from '@/components/baccarat/roads/ResultSmallRoad'
import ResultCockroachRoad from '@/components/baccarat/roads/ResultCockroachRoad'
import Timer from '@/components/common/LobbyTimer'

export default {
    name: 'GameRoomNoBigRoad',
    components: {
        ResultCount,
        // ResultRoad,
        ResultBigRoad,
        ResultBigEyeRoad,
        ResultSmallRoad,
        ResultCockroachRoad,
        Timer
    },
    created() {
        this.resizeRoundView();
    },
    data() {
        return {
            show: false,
            gameTimer: this.computeInitTimer(this.game.timer) || 0,
            roomRoundW: 0,
        }
    },
    props: {
        game: {
            required: true,
            type: Object
        },
        level: {
            required: true,
            type: Object,
            default: () => ({
                player_min: 0,
                player_max: 0
            })
        },
        lobbyImg: {
            required: true,
            type: String
        },
        selectedCols: {
            required: true,
            type: Number
        },
        gameCode: {
            required: false,
            type: String
        },
        roomCount: {
            required: false,
            type: Number
        },
    },
    watch: {
        game: {
            handler(newVal) {
                var vm = this;
                if (vm.gameTimer == 0){
                    let beforetime, currenttime, timediff, newtime;

                    beforetime = new Date(newVal.timerdate);
                    currenttime = new Date();

                    timediff = (currenttime.getTime() - beforetime.getTime()) / 1000;
                    newtime = newVal.timer - parseInt(timediff) ;
                    if (newtime < 0) {
                        newtime = 0;
                    } else if (newtime > newVal.timer) {
                        newtime = newVal.timer;
                    }
                    vm.gameTimer = newtime;
                }
            },
            deep: true
        },
        gameTimer: {
            handler(val) {
                if (val > 0) {
                    setTimeout(() => {
                        this.gameTimer--;
                    }, 1000);
                }
            },
            immediate: true,
            deep: true
        },
        selectedCols: {
            handler() {
                this.resizeRoundView();
            }
        },
    },
    computed: {
        commonMatrix() {
            let matrix = [],
                prevShoe = '',
                columnIdx = -1,
                rowIdx = 1,
                sameShoeCnt = 0,
                tempColumnIdx = -1,
                tmpInd = 5,
                tieCnt = 0,
                bigeyeArray = [],
                smallRoadArr = [],
                croachArr = []
            const tieShoe = ['i', 'j', 'k', 'l']
            this.game.shoe.split('').forEach((shoe) => {
                if (!(tieShoe.indexOf(shoe) !== -1)) { // If shoe is not tie
                    if (!((this.$prevShoeArr(prevShoe)).indexOf(shoe) !== -1)) { // create new column
                        if (matrix[columnIdx + 1]) { // check if there's an over shoe, overwrite 1st column
                            matrix[columnIdx + 1][0] = {
                                shoe,
                                count: 0,
                                isDragon: 0,
                                isNextCol: 0
                            }
                        } else {
                            matrix.push([{
                                    shoe,
                                    count: 0,
                                    isDragon: 0,
                                    isNextCol: 0
                                }, {
                                    shoe: '',
                                    count: 0,
                                    isDragon: 0,
                                    isNextCol: 0
                                }, {
                                    shoe: '',
                                    count: 0,
                                    isDragon: 0,
                                    isNextCol: 0
                                }, {
                                    shoe: '',
                                    count: 0,
                                    isDragon: 0,
                                    isNextCol: 0
                                },
                                {
                                    shoe: '',
                                    count: 0,
                                    isDragon: 0,
                                    isNextCol: 0
                                }, {
                                    shoe: '',
                                    count: 0,
                                    isDragon: 0,
                                    isNextCol: 0
                                }
                            ])
                        }
                        rowIdx = 1; // reset row index to 1 (next vertical shoe)
                        sameShoeCnt = 0 // reset same shoe count
                        columnIdx++; // increment column index
                        tempColumnIdx = columnIdx;
                        tieCnt = 0;

                        if (columnIdx >= 2) {
                            bigeyeArray.push(this.$shoePredict(matrix, columnIdx, 0));
                        }

                        if (columnIdx >= 3) {
                            smallRoadArr.push(this.$shoePredictTwo(matrix, columnIdx, 0));
                        }

                        if (columnIdx >= 4) {
                            croachArr.push(this.$shoePredictThree(matrix, columnIdx, 0));
                        }
                    } else { // normal add
                        if (sameShoeCnt > rowIdx) {
                            matrix.push([{
                                    shoe: '',
                                    count: 0,
                                    isDragon: 0,
                                    isNextCol: 0
                                }, {
                                    shoe: '',
                                    count: 0,
                                    isDragon: 0,
                                    isNextCol: 0
                                }, {
                                    shoe: '',
                                    count: 0,
                                    isDragon: 0,
                                    isNextCol: 0
                                }, {
                                    shoe: '',
                                    count: 0,
                                    isDragon: 0,
                                    isNextCol: 0
                                },
                                {
                                    shoe: '',
                                    count: 0,
                                    isDragon: 0,
                                    isNextCol: 0
                                }, {
                                    shoe: '',
                                    count: 0,
                                    isDragon: 0,
                                    isNextCol: 0
                                }
                            ])
                            tempColumnIdx++
                            matrix[tempColumnIdx][rowIdx] = {
                                shoe,
                                count: 0,
                                isDragon: 1,
                                isNextCol: 1
                            }

                            if (matrix[tempColumnIdx - 1][0].shoe != '' && matrix[tempColumnIdx - 1][1].shoe != '' && matrix[tempColumnIdx - 1][2].shoe != '' &&
                                matrix[tempColumnIdx - 1][3].shoe != '' && matrix[tempColumnIdx - 1][4].shoe != '' && matrix[tempColumnIdx - 1][5].shoe != '') {
                                matrix[tempColumnIdx - 1][0].isDragon = 1
                                matrix[tempColumnIdx - 1][1].isDragon = 1
                                matrix[tempColumnIdx - 1][2].isDragon = 1
                                matrix[tempColumnIdx - 1][3].isDragon = 1
                                matrix[tempColumnIdx - 1][4].isDragon = 1
                                matrix[tempColumnIdx - 1][5].isDragon = 1
                            }

                            if (columnIdx >= 2) {
                                bigeyeArray.push('a');
                            }

                            if (columnIdx >= 2) {
                                smallRoadArr.push('a');
                            }

                            if (columnIdx >= 3) {
                                croachArr.push('a');
                            }

                            if (tmpInd < 5) { //
                                if (matrix[tempColumnIdx][rowIdx + 1].shoe == '') {
                                    tempColumnIdx--
                                    rowIdx++
                                    tmpInd = rowIdx
                                }
                            }
                        } else {
                            if (matrix[tempColumnIdx] && matrix[tempColumnIdx][rowIdx].shoe == '') {
                                matrix[tempColumnIdx][rowIdx] = {
                                    shoe,
                                    count: 0,
                                    isDragon: 0,
                                    isNextCol: 0
                                }
                                if (columnIdx >= 2) {
                                    bigeyeArray.push(this.$shoePredict(matrix, tempColumnIdx, rowIdx));
                                }
                                if (columnIdx >= 2) {
                                    if (matrix[tempColumnIdx][rowIdx].shoe != '') {
                                        smallRoadArr.push(this.$shoePredictTwo(matrix, tempColumnIdx, rowIdx));
                                    }
                                }
                                if (columnIdx >= 3) {
                                    if (matrix[tempColumnIdx][rowIdx].shoe != '') {
                                        croachArr.push(this.$shoePredictThree(matrix, tempColumnIdx, rowIdx));
                                    }
                                }

                            } else {
                                tmpInd = rowIdx - 1
                                // check if the matrix has enough length to handle dragon tail
                                if (matrix.length < tempColumnIdx) {
                                    matrix.push([{
                                            shoe: '',
                                            count: 0,
                                            isDragon: 0,
                                            isNextCol: 0
                                        }, {
                                            shoe: '',
                                            count: 0,
                                            isDragon: 0,
                                            isNextCol: 0
                                        }, {
                                            shoe: '',
                                            count: 0,
                                            isDragon: 0,
                                            isNextCol: 0
                                        }, {
                                            shoe: '',
                                            count: 0,
                                            isDragon: 0,
                                            isNextCol: 0
                                        },
                                        {
                                            shoe: '',
                                            count: 0,
                                            isDragon: 0,
                                            isNextCol: 0
                                        }, {
                                            shoe: '',
                                            count: 0,
                                            isDragon: 0,
                                            isNextCol: 0
                                        }
                                    ])
                                }

                                matrix.push([{
                                        shoe: '',
                                        count: 0,
                                        isDragon: 0,
                                        isNextCol: 0
                                    }, {
                                        shoe: '',
                                        count: 0,
                                        isDragon: 0,
                                        isNextCol: 0
                                    }, {
                                        shoe: '',
                                        count: 0,
                                        isDragon: 0,
                                        isNextCol: 0
                                    }, {
                                        shoe: '',
                                        count: 0,
                                        isDragon: 0,
                                        isNextCol: 0
                                    },
                                    {
                                        shoe: '',
                                        count: 0,
                                        isDragon: 0,
                                        isNextCol: 0
                                    }, {
                                        shoe: '',
                                        count: 0,
                                        isDragon: 0,
                                        isNextCol: 0
                                    }
                                ])

                                tempColumnIdx++
                                rowIdx--
                                matrix[tempColumnIdx][rowIdx] = {
                                    shoe,
                                    count: 0,
                                    isDragon: 1,
                                    isNextCol: 1
                                }
                                if (columnIdx >= 2) {
                                    bigeyeArray.push('a');
                                }
                                if (columnIdx >= 2) {
                                    smallRoadArr.push('a');
                                }
                                if (columnIdx >= 3) {
                                    croachArr.push('a');
                                }

                                // Always check the bottom index
                                if (tmpInd < 5) {
                                    if (matrix[tempColumnIdx][rowIdx + 1].shoe == '') {
                                        tempColumnIdx--
                                        rowIdx++
                                        tmpInd = rowIdx
                                    }
                                }
                            }
                        }
                        if (rowIdx < tmpInd)
                            rowIdx++; // increment row index
                    }
                    prevShoe = shoe // set current shoe as prev
                    sameShoeCnt++; // same Shoe
                    tieCnt = 0 // reset tie counting.
                } else {
                    tieCnt++;
                    if (rowIdx > -1 && tempColumnIdx > -1) {
                        matrix[tempColumnIdx][rowIdx - 1] = {
                            shoe: matrix[tempColumnIdx][rowIdx - 1].shoe,
                            count: tieCnt,
                            isDragon: matrix[tempColumnIdx][rowIdx - 1].isDragon,
                            isNextCol: matrix[tempColumnIdx][rowIdx - 1].isNextCol
                        }
                    }
                }
            })
            return [bigeyeArray, smallRoadArr, croachArr]
        },
    },
    methods: {
         getEvoRoomno(roomno) {

            try {

                const roomSplit = roomno.split('-') // split for SP-A

                if(roomSplit[0] === 'SP') return this.$t('lobby.gameType.SBAC') + '-' + roomSplit[1]
                else if(roomSplit[0] === 'BC') return this.$t('lobby.gameType.BAC') + '-' + roomSplit[1]
                else if(roomSplit[0] === 'LGHT') return this.$t('lobby.gameType.LIGHTNING')
                else if (roomSplit[0] === 'KOSB') return this.$t('lobby.gameType.KOREANBAC') + '-' + roomSplit[1]

            } catch(e) {
                console.log(e)
            }
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        computeInitTimer(time) {
            let beforetime, currenttime, timediff, newtime = 0;

            beforetime = new Date(this.game.timerdate);
            currenttime = new Date();

            timediff = (currenttime.getTime() - beforetime.getTime()) / 1000;
            newtime = time - parseInt(timediff) ;
            if (newtime < 0) {
                newtime = 0;
            } else if (newtime > time) {
                newtime = time;
            }
            return newtime;
        },
        redirectGame(game, roomno, mobile) {
            this.show = !this.show

            this.$emit('pauseAudio');

            this.$store.commit('gameModule/SET_ROOM_DATA', {
                roomno,
                roomorder: '',
                state: 2,
                shoe: '',
                time: 0,
                result: null
            })
            
            if (mobile) {
                this.$router.push(`/m/game/${game}/${roomno}`);
            } else if (this.game.game === "CMB" && this.game.type == 'BULL') {
                this.$router.push(`/game/bull/${game}/${roomno}`);
            } else if (this.game.type == `DT`) {
                this.$router.push(`/game/dt/${game}/${roomno}`);
            } else {
                this.$router.push(`/game/${game}/${roomno}`);
            }
        },
        getRoundNumber(roomorder) {
            try {
                return roomorder.split('-')[1] || 0
            } catch (e) {
                return 0
            }
        },
        mouseIn() {
            this.show = true;
        },
        mouseLeave() {
            this.show = false;
        },
        drawGrids() {
            var lc = new Array(7).fill('').map(() => new Array(6).fill(''));
            return lc;
        },
        resizeRoundView() {
            if (this.selectedCols == 4) {
                this.roomRoundW = 3;
            }
            else if (this.selectedCols == 3) {
                this.roomRoundW = 8;
            } else {
                this.roomRoundW = 12;
            }
        },

    },
}
</script>

<style>
.bg-dark {
    background-color: rgb(0, 0, 0, 0.7) !important;
}

.play-game {
    position: absolute;
    left: 50%;
    bottom: auto !important;
    z-index: 12;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    height: auto !important;
    top: 45%;
    width: auto !important;
    color: #ffcf39;
    border: 1px solid #ffcf39;
    background-color: rgb(0, 0, 0, 0.2) !important;
    font-size: 2vh;
}

.rs-ctnr-t {
    width: 100%;
    position: absolute;
    top: 0px;
}

.grids {
    border-right: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
}

.div-left {
    float: left;
}

.bet-limit-div {
    margin-top: -5px;
}

.container-box {
    flex-grow: 1;
    margin: 0px;
    margin-right: 15px;
    background: white;
    border: 0px !important;
    padding: 0px !important;
    ;
    /* font-size: 15px; */
}

.badge-count {
    padding: 0.50em;
    border-radius: 1.5vh;
    height: 3vh;
    width: 3vh;
    font-size: 1.5vh;
}

.all-count,
.b-cnt,
.p-cnt,
.t-cnt,
.bp-span,
.pp-span {
    font-size: 2vh !important;
}

.pb-3,
.py-3 {
    padding-bottom: 0rem !important;
}

.pt-3,
.pb-3,
.pr-4 {
    overflow: hidden;
}

.card-shuffle {
    height: 100%;
    width: 100%;
    top: -30px;
    position: relative;
}

.image-shuffle {
    position: relative;
    text-align: center;
}

.bottom-text {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 0;
    right: 0;
    font-weight: 400;
    font-size: 1.5em;
}

</style><style>
@import '../../../assets/scss/main-gameroom.scss';
</style>

<!--=========================================================================================
  File Name: GameLobby.vue
  Description: Game Lobby Page
==========================================================================================-->
<template>
  <div class="content">
    <betting-history ref="modalName" />
    <div class="head row">
      <div class="balance-container col-sm">
        <div class="b-cont">
          <span class="balance-label">{{ $t("lobby.balanceLabel") }}</span>
          <span class="balance">{{ member.balance | currency }}</span>
        </div>
      </div>
      <div
        class="logo-container col-sm"
        :style="{
          backgroundImage: `url(${
            $t('publicImgUrl') + $t('gameLogoURL.' + gameCode)
          })`,
        }"
      ></div>
      <div class="user-container col-sm">
        <div class="right-col">
          <img
            src="/assets/images/gameroom/Profile_icon.png"
            class="img img-fluid top-icn-profile img-height-30"
          />
          <span class="user-info">
            {{ member.username }}
          </span>
          <span class="history-info">
            <img
              src="/assets/images/gameroom/btn_history.png"
              class="img img-fluid top-icn-history img-height-30 cursor-pointer"
              @click="$refs.modalName.openModal()"
            />
          </span>
          <b-dropdown :class="['language-selector']">
            <template slot="button-content">
              <!-- {{$t('lang.' + selectedLang.label)}}  -->
              <img
                class="img-fluid flags flgUi"
                v-bind:src="selectedLang.imgPath"
              />
            </template>
            <b-dropdown-item
              v-for="lang in langs"
              :key="`Lang${lang.code}`"
              :value="lang.code"
              @click="
                $i18n.locale = lang.code;
                selectLang(lang);
              "
            >
              <b-row
                style="
                  border-top: 1px solid rgb(190, 174, 147);
                  padding-top: 8px;
                "
              >
                <b-col md="6">
                  <span class="gold-text">{{ $t("lang." + lang.label) }}</span>
                </b-col>
                <b-col md="6">
                  <img v-bind:src="lang.imgPath" class="flags" />
                </b-col>
              </b-row>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>

    <div class="user-level-container">
      <b-row class="justify-content-md-center">
        <b-col
          class="level-detail"
          lg="2"
          sm="12"
          v-for="(userLevel, idx) in levels"
          :key="idx"
          :class="{
            selected:
              (lobby.selectedlevel == 0 ? userMaxLevel : lobby.selectedlevel) ==
              userLevel.level,
          }"
          @click="changeLevel(userLevel.level)"
        >
          <div>
            <span class="min-bet-label">{{
              $t("level." + userLevel.level)
            }}</span>
          </div>
          <div>
            <span class="min-bet-label">{{ $t("lobby.playerBetLimit") }}</span>
            <span class="min-bet-val"
              >{{ userLevel.player_min | currency("", ",", "0") }} ~
              {{ userLevel.player_max | currency("", ",", "0") }}</span
            >
          </div>
          <div>
            <span class="min-bet-label">{{ $t("lobby.bankerBetLimit") }}</span>
            <span class="min-bet-val"
              >{{ userLevel.banker_min | currency("", ",", "0") }} ~
              {{ userLevel.banker_max | currency("", ",", "0") }}</span
            >
          </div>
          <div>
            <span class="max-bet-label">{{ $t("lobby.tieBetLimit") }}</span>
            <span class="max-bet-val"
              >{{ userLevel.tie_min | currency("", ",", "0") }} ~
              {{ userLevel.tie_max | currency("", ",", "0") }}</span
            >
          </div>
          <div>
            <span class="max-bet-label">{{ $t("lobby.pairBetLimit") }}</span>
            <span class="max-bet-val"
              >{{ userLevel.pair_min | currency("", ",", "0") }} ~
              {{ userLevel.pair_max | currency("", ",", "0") }}</span
            >
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="game-type-container">
      <b-row
        class="justify-content-md-center"
        style="
          background: linear-gradient(
            358deg,
            #161616 0%,
            #232323 35%,
            #303030 100%
          );
          border-top: 1px solid #beae93;
          border-bottom: 1px solid #beae93;
          margin-left: 2vw;
          margin-right: 2vw;
        "
      >
        <b-col
          md="1"
          :class="{ active: lobby.selectedGameType == '' }"
          v-on:click="changeGameType('')"
        >
          <span class="gold-text">{{ $t("lobby.gameType.all") }}</span>
        </b-col>

        <b-col
          md="1"
          :class="{ active: lobby.selectedGameType == type }"
          v-for="(type, index) in gameTypes"
          :key="index"
          @click="changeGameType(type)"
        >
          <span class="gold-text">{{ $t("lobby.gameType." + type) }}</span>
        </b-col>
      </b-row>
    </div>
    <div class="lobby-list">
      <div class="separator">
        <div class="column-selector">
          <span class="four-column" @click="colsDiv(4)"></span>
          <span class="three-column" @click="colsDiv(3)"></span>
          <span class="two-column" @click="colsDiv(2)"></span>
        </div>
      </div>
      <!-- gm-active-filter -->
      <b-row style="margin-left: 0px" class="game-filter-cont">
        <b-col
          v-if="lobby.selectedcategory != ''"
          cols="1"
          style="
            background: linear-gradient(
              358deg,
              rgb(22, 22, 22) 0%,
              rgb(35, 35, 35) 35%,
              rgb(48, 48, 48) 100%
            );
            border-left: 1px solid #beae93;
            border-right: 1px solid #beae93;
          "
        >
          <b-col
            :class="{ gmActive: lobby.selectedcategory == category }"
            md="12"
            v-for="(category, index) in gameCategory"
            :key="index"
            @click="changeCategory(category)"
            style="
              cursor: pointer;
              border-bottom: #beae93 1px solid;
              padding-top: 1.2vh;
              padding-bottom: 1.2vh;
            "
          >
            <span class="gold-text">{{
              $t("lobby.gameCategory." + category)
            }}</span>
          </b-col>
        </b-col>
        <b-col cols="11">
          <div class="game-list" style="overflow: hidden; margin-top: 2vw">
            <div
              v-for="(room, idx) in gameRooms"
              :key="idx"
              :class="{
                twoCols: cols == 2,
                threeCols: cols == 3,
                fourCols: cols == 4,
              }"
              style="float: left; padding-bottom: 30px"
            >
              <div
                v-if="lobby.selectedGameType != 'BULL' && room.type != 'BULL'"
              >
                <game-room-main
                  v-if="lobbyDisplay == 'MAIN'"
                  :game="room"
                  :lobbyImg="gameLobbyImg"
                  :level="selectedLevelDetails()"
                  :selectedCols="cols"
                  @pauseAudio="pauseAudio"
                  :gameCode="gameCode"
                  :roomCount="idx"
                />
                <game-room-no-big-road
                  v-if="lobbyDisplay == 'NO_BIG_ROAD'"
                  :game="room"
                  :lobbyImg="gameLobbyImg"
                  :level="selectedLevelDetails()"
                  :selectedCols="cols"
                  @pauseAudio="pauseAudio"
                  :gameCode="gameCode"
                  :roomCount="idx"
                />
              </div>
              <div
                v-if="
                  (lobby.selectedGameType == 'BULL' ||
                    lobby.selectedGameType == '') &&
                  room.type == 'BULL'
                "
              >
                <bull-game-room
                  :game="room"
                  :lobbyImg="gameLobbyImg"
                  :level="selectedLevelDetails()"
                  :selectedCols="cols"
                  @pauseAudio="pauseAudio"
                  :roomCount="idx"
                />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import GameRoomMain from "@/components/baccarat/lobby/GameRoom";
import GameRoomNoBigRoad from "@/components/baccarat/lobby/GameSwitchRoom";
import BullGameRoom from "@/components/bull/lobby/BullGameRoom";
import BettingHistory from "@/views/common/pc/BettingHistory";

export default {
  name: "GameLobby",
  components: {
    GameRoomMain,
    GameRoomNoBigRoad,
    BettingHistory,
    BullGameRoom,
  },
  async beforeCreate() {
    await this.$store.dispatch("gameModule/checkIfLoggedIn");

    this.lobby.selectedlevel = this.$store.state.gameModule.levels.length;
    this.$store.commit(
      "gameModule/SET_CURRENT_LEVEL",
      this.lobby.selectedlevel
    );
  },
  mounted() {
    this.lobby.selectedcategory = this.gameCategory[0] || "";
  },
  data() {
    return {
      cols: 3,
      bullcols: 2,
      lobby: {
        selectedGameType: "",
        selectedlevel: 0,
        selectedcategory: "",
      },
      langs: [
        {
          code: "ko-KR",
          label: "korean",
          imgPath: "/assets/images/gamelobby/flags/korea.png",
        },
        {
          code: "en-US",
          label: "english",
          imgPath: "/assets/images/gamelobby/flags/usa.png",
        },
        {
          code: "ch-CH",
          label: "chinese",
          imgPath: "/assets/images/gamelobby/flags/china.png",
        },
      ],
      selectedLang: {},
      gameLogo:
        "http://game.bet4api.com/assets/images/logos-header/88_logo.png",
      gameLobbyImg: "http://game.bet4api.com/assets/images/logos/op_logo.png",
      bgAudio: new Audio("/assets/sounds/ko/bgm1.mp3"),
      roomList: [],
    };
  },
  computed: {
    gameRooms() {
      if (this.lobby.selectedGameType === "")
        return this.$store.state.gameModule.game.rooms.filter(
          (r) => r.category === this.lobby.selectedcategory
        );
      else
        return this.$store.state.gameModule.game.rooms.filter(
          (r) =>
            r.category === this.lobby.selectedcategory &&
            r.type === this.lobby.selectedGameType
        );
    },
    gameCategory() {
      return window._.uniq(
        window._.map(this.$store.state.gameModule.game.rooms, "category")
      );
    },
    gameTypes() {
      return window._.uniq(
        window._.map(this.$store.state.gameModule.game.rooms, "type")
      );
    },
    member() {
      return this.$store.state.gameModule.member;
    },
    levels() {
      return this.$store.state.gameModule.levels;
    },
    userMaxLevel() {
      return this.$store.state.gameModule.levels.length;
    },
    gameCode() {
      return this.$store.state.gameModule.game.code;
    },
    lobbyDisplay() {
      return this.$store.state.gameModule.settings.lobbyRoomDisplay;
    },
  },
  methods: {
    changeCategory(category) {
      this.lobby.selectedcategory = category;
    },
    changeGameType(id = "") {
      this.lobby.selectedGameType = id;
    },
    changeLevel(id) {
      this.lobby.selectedlevel = id;
      this.$store.commit("gameModule/SET_CURRENT_LEVEL", id);
    },
    filteredGamesByType() {
      if (this.lobby.selectedGameType != "") {
        return this.games.filter(
          (game) => game.gameType == this.lobby.selectedGameType
        );
      } else {
        return this.games;
      }
    },
    selectedLevelDetails() {
      return this.levels.filter(
        (level) => level.level == this.lobby.selectedlevel
      )[0];
    },
    colsDiv(v) {
      this.cols = v;
    },
    selectLang(lang) {
      this.selectedLang = lang;
      localStorage.setItem("langLabel", lang.label);
      localStorage.setItem("langimg", lang.imgPath);
      localStorage.setItem("langcode", lang.code);
    },
    pauseAudio() {
      this.bgAudio.pause();
      localStorage.setItem("bgmPlaying", false);
    },
    isMobile() {
      if (
        /Android|android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.selectedLang.label = localStorage.getItem("langLabel");
    this.selectedLang.imgPath = localStorage.getItem("langimg");
    this.selectedLang.code = localStorage.getItem("langcode");

    if (this.selectedLang.code == null) {
      this.selectedLang = this.langs[0];
      localStorage.setItem("langLabel", this.selectedLang.label);
      localStorage.setItem("langimg", this.selectedLang.imgPath);
      localStorage.setItem("langcode", this.selectedLang.code);
    } else {
      this.selectedLang = {
        label: this.selectedLang.label,
        imgPath: this.selectedLang.imgPath,
        code: this.selectedLang.code,
      };
    }

    this.$i18n.locale = this.selectedLang.code;

    this.bgAudio.loop = true;
    if (
      localStorage.getItem("muteAudio") == 0 &&
      localStorage.getItem("bgmPlaying") == "false"
    ) {
      this.bgAudio.play();
      localStorage.setItem("bgmPlaying", true);
    }
  },
};
</script>
<style>
body {
  width: 100%;
  height: 100%;
  color: #fff;
  background: #000;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.dropdown-toggle {
  height: 3vh !important;
  width: 3.2vh !important;
  border-radius: 50%;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  display: none;
}

.shoe-info {
  position: absolute;
}

.flags {
  height: auto;
  width: 1vw;
  right: 0px;
}

.twoCols {
  width: 46%;
  margin-right: 2%;
  margin-left: 2%;
}
.threeCols {
  width: 31%;
  margin-left: 1.2%;
  margin-right: 1%;
}

.fourCols {
  width: 24%;
  margin-right: 0.5%;
  margin-left: 0.5%;
}

.b-cont {
  height: 100%;
  display: table;
  margin-left: 5vw;
}
.balance-label,
.balance {
  display: table-cell;
  vertical-align: middle;
}

.user-info {
  font-weight: bold;
}

.history-info {
  cursor: pointer;
  margin: 0 1vw;
}

.flgUi {
  height: 3vh !important;
  width: 3.2vh !important;
  position: absolute !important;
  top: -1px !important;
  left: 0px !important;
  border-radius: 50%;
  border: 2px solid rgb(190, 174, 147);
}
.dropdown-menu.show {
  position: absolute;
  will-change: transform;
  background: linear-gradient(
    358deg,
    rgb(22, 22, 22) 0%,
    rgb(35, 35, 35) 35%,
    rgb(48, 48, 48) 100%
  );
  border: 1px solid rgb(190, 174, 147);
  top: 0.5vh !important;
  left: -5vh !important;
  transform: translate3d(0px, 29px, 0px);
}
</style>

<!--=========================================================================================
  File Name: ResultBigEyeRoad.vue
  Description: Result Big Eye Road Component
==========================================================================================-->
<template>
<div class="content grid-lines" style="padding-top: 0.16vh">
    <div class="carfbig-road" ref="shoe-tbl">
        <div class="rs-ctnr d-flex flex-row grid-y grid-y-repeat-big" v-bind:style="{width: shoeW }">
             <div class="flex-column" v-for="(shoeM,x) in shoeMatrixBigEye" :key="x">
                 <div v-for="(shoe, i) in shoeM" v-bind:key="shoe.index + '-' + i">
                     <div class="big-road-shoe" style="height:0.8vh;width: 0.8vh;"
                           v-bind:class="[{ animate_latest: x == shoeMatrixBigEye.length - 1 && (shoe.shoe !='' && i == $getLasttIndex(shoeMatrixBigEye, x) )}, shoe.shoe + '-big-eye-road']">
                     </div>
                 </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'ResultBigEyeRoad',
    components: {},
    data() {
        return {
          width: 0,
          window: {
              width: 0,
              height: 0
          },
          scrollW: 0,
          shoeW: '3000px'
        }
    },
    props: {
        commonMatrix: {
            required: true,
            type: Array
        },
    },
    watch: {
      shoeMatrixBigEye: {
          handler(){
            this.handleResize();
          }
      }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        if (this.shoeMatrixBigEye.length > 32) {
            this.handleResize()
        }
    },
    methods: {
      handleResize() {
          this.window.width = window.innerWidth;
          this.window.height = window.innerHeight;
          this.scrollW = (this.window.height * 0.8) / 100;
          if(this.$refs['shoe-tbl'])
            this.$refs['shoe-tbl'].scrollLeft = (this.shoeMatrixBigEye.length - 32) * this.scrollW
      },
      scrollTarget(move) {
          if (move == 'right') this.$refs['shoe-tbl'].scrollLeft += this.scrollW
          else this.$refs['shoe-tbl'].scrollLeft -= this.scrollW
      },
    },
    computed: {
        shoeMatrixBigEye() {
        // Initialized the bigeyeroad data
        let matrix = [], prevShoe = '', columnIdx = -1, rowIdx = 1, sameShoeCnt = 0, tempColumnIdx = -1,  tmpInd = 5
           this.commonMatrix.forEach((shoe) => {
                if(!((this.$prevShoeArr(prevShoe)).indexOf(shoe) !== -1)) { // create new column
                    if(matrix[columnIdx + 1]) { // check if there's an over shoe, overwrite 1st column
                        matrix[columnIdx + 1][0] = { shoe, count:  0}
                    } else {
                        matrix.push([{ shoe, count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                    }
                    rowIdx = 1; // reset row index to 1 (next vertical shoe)
                    sameShoeCnt = 0 // reset same shoe count
                    columnIdx++; // increment column index
                    tempColumnIdx = columnIdx;
                } else { // normal add
                    if (sameShoeCnt > rowIdx) {
                        matrix.push([{  shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                        tempColumnIdx++
                        matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 }
                        if (tmpInd < 5) { //
                            if(matrix[tempColumnIdx][rowIdx+1].shoe == '') {
                                tempColumnIdx--
                                rowIdx++
                                tmpInd = rowIdx
                            }
                        }
                    } else {
                        if(matrix[tempColumnIdx] && matrix[tempColumnIdx][rowIdx].shoe == '') {
                            matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 }
                        } else {
                            tmpInd = rowIdx - 1
                            // check if the matrix has enough length to handle dragon tail
                            if(matrix.length < tempColumnIdx)
                            {
                                matrix.push([{  shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                            }
                            matrix.push([{  shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                            tempColumnIdx++
                            rowIdx--
                            matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 }
                            // Always check the bottom index
                            if (tmpInd < 5) {
                                if(matrix[tempColumnIdx][rowIdx+1].shoe == '') {
                                    tempColumnIdx--
                                    rowIdx++
                                    tmpInd = rowIdx
                                }
                            }
                        }
                    }
                if(rowIdx < tmpInd)
                    rowIdx++; // increment row index
                }
                prevShoe = shoe // set current shoe as prev
                sameShoeCnt++; // same Shoe
            })
        return matrix
       }
   }
}
</script>

<style>
.big-eye-road-tbl td {
    border: 0.5px solid #D8D8D8;
    border-top: none !important;
}

.big-eye-road-tbl {
    display: block;
    overflow: hidden;
}
</style>

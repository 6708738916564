<!--=========================================================================================
  File Name: Result CockroachRoad.vue
  Description: Result Cockroach Road Result Component
==========================================================================================-->
<template>
<div class="content" style="padding: 0px">
    <div class="cockroach-road">
        <div class="rs-ctnr d-flex flex-row grid-y grid-y-repeat-roach">
            <div class="flex-column" v-for="(shoeM,x) in shoeCockroach" :key="x">
                    <div v-for="(shoe, i) in shoeM" v-bind:key="shoe.index + '-' + i" style="height:0.8vh;width: 0.8vh;">
                        <div class="big-road-shoe" style="height:0.8vh;width: 0.8vh;"
                            v-bind:class="[{ animate_latest: x == shoeCockroach.length - 1 && (shoe.shoe !='' && i == $getLasttIndex(shoeCockroach, x) )}, shoe.shoe + '-cockroach-road']">
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'ResultCockroachRoad',
    components: {},
    data() {
        return {}
    },
    props: {
        commonMatrix: {
            required: true,
            type: Array
        },
        shoeResults: {
            required: true,
            type: String
        }
    },
    computed: {
        shoeCockroach() {
            let matrix = [], prevShoe = '', columnIdx = -1, rowIdx = 1, sameShoeCnt = 0, tempColumnIdx = -1,  tmpInd = 5
            this.commonMatrix.forEach((shoe) => {
                    if(!((this.$prevShoeArr(prevShoe)).indexOf(shoe) !== -1)) { // create new column
                        if(matrix[columnIdx + 1]) { // check if there's an over shoe, overwrite 1st column
                            matrix[columnIdx + 1][0] = { shoe, count:  0}
                        } else {
                            matrix.push([{ shoe, count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                        }
                        rowIdx = 1; // reset row index to 1 (next vertical shoe)
                        sameShoeCnt = 0 // reset same shoe count
                        columnIdx++; // increment column index
                        tempColumnIdx = columnIdx;
                    } else { // normal add
                        if (sameShoeCnt > rowIdx) {
                            matrix.push([{  shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                            tempColumnIdx++
                            matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 }
                            if (tmpInd < 5) { //
                                if(matrix[tempColumnIdx][rowIdx+1].shoe == '') {
                                    tempColumnIdx--
                                    rowIdx++
                                    tmpInd = rowIdx
                                }
                            }
                        } else {
                            if(matrix[tempColumnIdx] && matrix[tempColumnIdx][rowIdx].shoe == '') {
                                matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 }
                            } else {
                                tmpInd = rowIdx - 1
                                // check if the matrix has enough length to handle dragon tail
                                if(matrix.length < tempColumnIdx)
                                {
                                    matrix.push([{  shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                                }

                                matrix.push([{  shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }, { shoe: '', count: 0 }])
                                tempColumnIdx++
                                rowIdx--
                                matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 }

                                // Always check the bottom index
                                if (tmpInd < 5) {
                                    if(matrix[tempColumnIdx][rowIdx+1].shoe == '') {
                                        tempColumnIdx--
                                        rowIdx++
                                        tmpInd = rowIdx
                                    }
                                }
                            }
                        }
                    if(rowIdx < tmpInd)
                        rowIdx++; // increment row index
                    }
                    prevShoe = shoe // set current shoe as prev
                    sameShoeCnt++; // same Shoe
             })
             return matrix
        }
    }
}
</script>

<style>
.cockroach-road-tbl td {
    border: 1px solid #D8D8D8;
    border-top: none !important;
}

.cockroach-road-tbl {
    display: block;
    overflow: hidden;
}
</style>
